<template>
    <v-app id="inspire">
        <v-container
            class="fill-height"
            fluid
        >
            <v-row
                align="center"
                justify="center"
                style="max-width: initial"
            >
                <v-col
                    cols="12"
                    sm="8"
                    md="4"
                    class="px-6"
                    style="max-width: 480px"
                >
                    <v-card
                        shaped
                        elevation="10"
                    >
                        <v-toolbar
                            color="indigo darken3"
                            dark
                            flat
                        >
                            <v-toolbar-title class="title">TrendView Console 로그인</v-toolbar-title>
                        </v-toolbar>
                        <v-card-text>
                            <v-form
                                ref="form"
                                lazy-validation
                                @keyup.native.enter="submit"
                            >
                                <v-text-field
                                    v-model="email"
                                    label="ID"
                                    prepend-icon="email"
                                    :rules="emailRules"
                                    color="indigo darken3"
                                    required
                                />

                                <v-text-field
                                    v-model="password"
                                    label="Password"
                                    type="password"
                                    prepend-icon="lock"
                                    :rules="passwordRules"
                                    color="indigo darken3"
                                    required
                                />
                            </v-form>
<!--                            <v-btn color="#dc3545"-->
<!--                                   class="subtitle-2 font-weight-bold ml-8 justify-start"-->
<!--                                   style="width: 370px; color: #ffffff;"-->
<!--                                   @click.prevent="socialLogin('google')"-->
<!--                            >-->
<!--                              <v-icon small>mdi-google</v-icon>-->
<!--                              <span style="width: 100%;" class="justify-center pr-3">Google 계정으로 계속하기</span>-->
<!--                            </v-btn>-->
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer/>
                            <v-btn color="indigo darken3" class="subtitle-2 font-weight-bold" text @click="submit">LOGIN</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-app>
</template>

<script>
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import {mapGetters, mapActions} from 'vuex'

export default {
    data() {
        return {
            email: '',
            emailRules: [
                v => !!v || '아이디를 입력해 주세요.',
            ],
            password: '',
            passwordRules: [
                v => !!v || '비밀번호를 입력해 주세요.',
            ],
            token: '',
            grant_type: 'password',
            client_id: '93fff73e-62d6-44c8-82a3-da3e2f5275b8',
            client_secret: 'OsDF2iplB0PbXNm3nvcJpcZdaYJN0tj4A4pqicl5',
            scope: '*'
        }
    },
    computed: {
        ...mapGetters({
            authenticated: 'auth/authenticated',
            user: 'auth/user',
        }),
        form() {
            return {
                username: this.email,
                password: this.password,
                grant_type: this.grant_type,
                client_id: this.client_id,
                client_secret: this.client_secret,
                scope: this.scope
            }
        },
    },
  mounted() {
      this.googleLoginCheck();
  },
  methods: {
        ...mapActions({
            login: 'auth/login',
            google_login: 'auth/google_login',
            logoutAction: 'auth/logout'

        }),

        logout() {
          this.logoutAction();
        },
        socialLogin(site) {
          window.location = process.env.VUE_APP_AXIOS_BASE_URL+site;
        },

        googleLoginCheck() {
          let token = this.$route.query.token;

          if (token) {
              this.google_login(token).then(() => {
                if (this.authenticated) {
                  if(!this.user.user_media[0]){
                    alert('지정된 매체가 없습니다. 최고 관리자에게 문의하세요.');
                    this.logout();
                  }else{
                    this.$router.replace({
                      name: 'Home'
                    });
                  }
                } else {
                  alert('아이디나 비밀번호를 확인해주세요.');
                }
              })
            }
        },
        submit() {
            if (this.$refs.form.validate()) {
                this.login(this.form).then(() => {
                    if (this.authenticated) {
                      if(!this.user.user_media[0]){
                          alert('지정된 매체가 없습니다. 최고 관리자에게 문의하세요.');
                          this.logout();
                      }else{
                          this.$router.replace({
                              name: 'Home'
                          });
                      }
                    } else {
                        alert('아이디나 비밀번호를 확인해주세요.');
                    }
                })
            }
        },

    }
}
</script>
