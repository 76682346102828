<template>
    <v-container >
        <Breadcrumbs :breadcrumbs="breadcrumbs"/>
      <v-row class="row mb-5 mt-4">
        <v-col cols="12" sm="12" md="12" lg="6">
          <v-card style="padding: 5px;" class="view-card-height"
              outlined
          >
            <span class="ml-3 subtitle-1 font-weight-medium">
            <v-icon color="indigo darken-3">mdi-clipboard-text</v-icon>
            상세정보
            </span>
            <v-card-title>
              <v-row>
                <v-col>
                  <v-row no-gutters>
                    <v-text-field
                        :value="detail.type"
                        label="키워드/채널"
                        readonly
                        persistent-hint
                    ></v-text-field>
                    <v-text-field
                        v-model="detail.platform"
                        label="플랫폼"
                        readonly
                        persistent-hint
                    ></v-text-field>
                  </v-row>
<!--                  <v-text-field-->
<!--                      v-model="detail.keyword"-->
<!--                      label="키워드"-->
<!--                      readonly-->
<!--                      persistent-hint-->
<!--                  ></v-text-field>-->
                  <v-textarea
                      :value="detail.title"
                      label="제목"
                      rows="2"
                      readonly
                      persistent-hint
                      outlined
                  ></v-textarea>
                  <v-textarea
                      :value="detail.contents"
                      label="내용"
                      rows="7"
                      readonly
                      persistent-hint
                      outlined
                  ></v-textarea>
                  <v-row no-gutters>
                    <v-text-field
                        class="mt-6"
                        v-model="detail.date"
                        label="작성날짜"
                        readonly
                        persistent-hint
                    ></v-text-field>
                    <v-text-field
                        v-if="detail.state === 1"
                        v-model="detail.updated_at"
                        label="게시날짜"
                        readonly
                        persistent-hint
                    ></v-text-field>
                  </v-row>
                </v-col>
                <v-col>
                  <v-row no-gutters>
                    <v-text-field
                        v-model="tags"
                        label="해시태그"
                        readonly
                        persistent-hint
                    ></v-text-field>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }" >
                        <span style="width: 100%;cursor: pointer;margin:0px;" class="text-center text-subtitle-2"
                              v-bind="attrs"
                              v-on="on"
                        >
                          <v-text-field
                              prefix="#"
                              v-model="detail.business_tag"
                              label="키워드 (PK)"
                              readonly
                              persistent-hint
                              append-outer-icon="mdi-content-save-edit-outline"
                              @click:append-outer="editBusinessTag"
                              @click="editBusinessTag"
                          ></v-text-field>
                          </span>
                      </template>
                      <span>클릭 시 키워드 (PK)를 수정할 수 있습니다.</span>
                    </v-tooltip>
                    <v-dialog
                        v-model="editTagDialog"
                        max-width="500px"
                    >
                      <v-card>
                        <v-card-title>
                          <span class="text-h5">키워드 (PK) 수정</span>
                        </v-card-title>
                        <v-card-text>
                          <v-container>
                            <v-row>
                              <v-col>
                                <v-textarea
                                    outlined
                                    v-model="modifyTag"
                                    label="* 키워드 (PK)가 여러개일 경우 쉼표로 구분해주세요."
                                ></v-textarea>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                              class="white--text"
                              color="#1239A3"
                              @click="save"
                          >
                            저장
                          </v-btn>
                          <v-btn
                              class="white--text"
                              color="#E23C44"
                              @click="close"
                          >
                            취소
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-row>
                  <v-text-field
                      v-model="detail.channel"
                      label="채널"
                      readonly
                      persistent-hint
                  ></v-text-field>
                  <v-text-field
                      v-model="detail.url"
                      label="URL"
                      readonly
                      persistent-hint
                  ></v-text-field>
                  <v-text-field
                      v-model="detail.thumbnail_url"
                      label="썸네일 URL"
                      readonly
                      persistent-hint
                  ></v-text-field>
                  <v-text-field
                      v-model="detail.storage_thumbnail_url"
                      label="스토리지 썸네일 URL"
                      readonly
                      persistent-hint
                  ></v-text-field>
                  <v-row no-gutters>
                    <v-text-field
                        v-model="detail.thumbnail_width"
                        label="썸네일 넓이"
                        readonly
                        persistent-hint
                    ></v-text-field>
                    <v-text-field
                        v-model="detail.thumbnail_height"
                        label="썸네일 높이"
                        readonly
                        persistent-hint
                    ></v-text-field>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-title>
          </v-card>
        </v-col>
        <v-col cols="12" sm="12" md="12" lg="6">
          <v-spacer style="width: 10%;"></v-spacer>
          <v-card style="padding: 5px;" class="view-card-height"
              outlined
          >
            <span class="ml-3 subtitle-1 font-weight-medium">
            <v-icon color="indigo darken-3">mdi-account</v-icon> 게시자 정보
            </span>
            <v-card-title>
              <v-row>
                <v-col>
                  <v-text-field
                      v-model="owner.id"
                      label="게시자 아이디"
                      readonly
                      persistent-hint
                  ></v-text-field>
                  <v-text-field
                      v-model="owner.url"
                      label="URL"
                      readonly
                      persistent-hint
                  ></v-text-field>
                  <v-text-field
                      v-model="owner.thumbnail_url"
                      label="썸네일 URL"
                      readonly
                      persistent-hint
                  ></v-text-field>
                  <v-text-field
                      v-model="owner.storage_thumbnail_url"
                      label="스토리지 썸네일 URL"
                      readonly
                      persistent-hint
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                      v-model="owner.name"
                      label="게시자 이름"
                      readonly
                      persistent-hint
                  ></v-text-field>
                  <v-text-field
                      v-model="owner.platform"
                      label="플랫폼"
                      readonly
                      persistent-hint
                  ></v-text-field>
                  <v-text-field
                      v-model="owner.thumbnail_width"
                      label="썸네일 넓이"
                      readonly
                      persistent-hint
                  ></v-text-field>
                  <v-text-field
                      v-model="owner.thumbnail_height"
                      label="썸네일 높이"
                      readonly
                      persistent-hint
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-title>
          </v-card>
        </v-col>
      </v-row>

      <v-row class="mb-5">
        <v-col class="col-lg-12">
          <v-card class="" style="padding: 10px;"
                  outlined
          >
            <span class="ml-3 subtitle-1 font-weight-medium">
            <v-icon color="indigo darken-3">mdi-file</v-icon> 미디어 정보
            </span>
            <v-data-table
                :headers="headers"
                :items="items"
                sort-by="calories"
                class="mt-3"
                :items-per-page="5"
                :footer-props="{
                   'items-per-page-text':'행 개수 : ',
                  'items-per-page-options': [5, 10, 15, 20, 25]
                }"
            >
              <template v-slot:item.storage_url="{ item }">
                <img :src="item.storage_url" style="width: 60px; height: 60px" class="mt-2"
                     v-if="item.storage_url"/>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>

      <v-row class="mb-5">
        <v-col class="col-lg-12">
          <v-card style="padding: 10px;"
              outlined
        >
          <span class="ml-3 subtitle-1 font-weight-medium">
          <v-icon color="red darken-2">mdi-comment-alert</v-icon>
          신고내역
          </span>
          <v-data-table
              :headers="reports_headers"
              :items="reports"
              sort-by="calories"
              class="mt-3"
              :items-per-page="5"
              :footer-props="{
                 'items-per-page-text':'행 개수 : ',
                 'items-per-page-options': [5, 10, 15, 20, 25]
              }"
          >
          </v-data-table>
        </v-card>
        </v-col>
      </v-row>

    </v-container>
</template>

<script>
import Breadcrumbs from "../../components/Breadcrumbs";

export default {
    components: {
        Breadcrumbs,
    },
    data() {
        return {
            loading: false,
            editTagDialog: false,
            modifyTag: '',
            breadcrumbs: [
                {

                    text: '뒤로가기',
                    value: 'back',
                    disabled: false,
                    link: true,
                    exact: true,
                    page: this.page,
                },

                {
                    text: '게시글 상세보기',
                    disabled: false,
                },
            ],
            headers: [
                {text: '타입', value: 'type', sortable: false, width: '100px'},
                {text: '스토리지 이미지', value: 'storage_url', sortable: false, width: '150px'},
                {text: 'URL', value: 'url', sortable: false},
                {text: '넓이', value: 'width', sortable: false, width: '150px'},
                {text: '높이', value: 'height', sortable: false, width: '150px'},
            ],
            reports_headers: [
                {text: '아이디', value: 'user_id', sortable: false, width: '200px'},
                {text: '이름', value: 'user_name', sortable: false, width: '200px'},
                {text: '신고유형', value: 'type', sortable: false, width: '400px', align: 'center'},
                {text: '신고내용', value: 'description', sortable: false, align: 'center'},
                {text: '날짜', value: 'created_at', sortable: false, width: '200px'},
            ],
            items: [],
            detail: [],
            owner: [],
            tags: [],
            reports: [],
            no: '',
            search: '',
            page: this.$route.query.page || 1
        }
    },
    watch: {
      //해당 라우트에서 주소가 바꼈을시 호출됨
      '$route' (to, from) {
        // console.log(to.hash);
        // console.log(from);
        to;
        from;
      }
    },
    mounted() {
        if (this.$route.query.search) {
          this.search = this.$route.query.search;
        }
        this.getData();
        this.getReport();
    },
    methods: {
        getData() {
            // let url = '';
            // let updated_dt = '';
            // url = 'https://chuncheon.blob.core.windows.net/chuncheon/';

            let url = this.$root.getStorageUrlByMediaName(this.$route.query.tv);

            /*if (this.$route.query.c9 === 'bloodpressure') {
                url = 'https://c9bloodpressure.azureedge.net/';
            } else if (this.$route.query.c9 === 'golfzip') {
                url = 'https://c9golfzip.azureedge.net/';
            } else if (this.$route.query.c9 === 'ticketoffice') {
                url = 'https://c9ticketoffice.azureedge.net/';
            } else if (this.$route.query.c9 === 'tingstar') {
                url = 'https://c9tingstar.azureedge.net/';
            } else if (this.$route.query.c9 === 'sivillage') {
                url = 'https://c9sivillage.azureedge.net/';
            }*/

            this.loading = true
            this.axios.get('api/v1/articles/' + this.$route.params.id + '/detail', {
                headers: {
                    'tv': this.$route.query.tv,
                    'Authorization': localStorage.getItem('Authorization')
                }
            })
                .then(res => {
                    if (res.data.data.article_medias.length > 0) {
                        this.items = res.data.data.article_medias.map((item, index) => {
                            return {
                                no: index,
                                id: item.id,
                                url: item.url,
                                type: item.type,
                                storage_url: item.storage_url ? url + item.storage_url : '',
                                width: item.width,
                                height: item.height,
                                updated_at: item.updated_at
                            }
                        })
                    }
                    this.detail = res.data.data;
                    this.modifyTag = res.data.data.business_tag?.replaceAll('#', ',');
                    this.owner = res.data.data.article_owner ?? '';
                    const str = this.detail.hashtag;
                    if (str) {
                        const arr = str.split(' ', -1);
                        this.tags = arr;
                    } else {
                        this.tags = '';
                    }

                })
                .catch(err => {
                    console.error(err);
                });
        },
        getReport() {
            this.loading = true
            this.axios.get('api/v1/article-reports/' + this.$route.params.id, {
                headers: {
                    'tv': this.$route.query.tv,
                }
            })
                .then(res => {
                    if (res.data.data.length > 0) {
                        this.reports = res.data.data.map((item, index) => {
                            return {
                                no: index,
                                id: item.user_id,
                                user_name: item.user_name,
                                type: item.type,
                                description: item.description,
                                created_at: item.created_at.toString().substr(0, 10)
                            }
                        })
                    }
                    this.reports = res.data.data;
                })
                .catch(err => {
                    console.error(err);
                });
        },
      editBusinessTag() {
          this.editTagDialog = true;
      },
      save() {
        let header = {
            headers: {
              'tv': this.$route.query.tv
            }
          };
          const data = {
            media_id: this.$store.getters.getMediaId,
            tags: this.modifyTag
          };
          if(this.modifyTag.trim() === this.detail.business_tag?.trim()){
            this.$Swal.fire('기존 키워드 (PK)와 동일합니다.', '', 'info')
          } else {
            this.axios.put('api/v1/articles/' + this.detail.id + '/tag', data, header).then(res => {
              this.detail.business_tag = res.data.data.business_tag;
              this.modifyTag = res.data.data.business_tag.replaceAll('#', ',');
              this.editTagDialog = false;
              // location.reload();
            }).catch(err => {
              console.error(err);
            });

          }

      },
      close() {
          this.editTagDialog = false;
      },
    }
}
</script>

<style scoped>
.theme--light.v-text-field>.v-input__control>.v-input__slot:before {
  border-color: #ffffff;
}
@media screen and (min-width:2560px) {
  .view-card-height {
    height: 562px;
  }

}
@media screen and (min-width:2048px) and (max-width:2559px) {

  .view-card-height {
    height: 580px;
  }

}
@media screen and (min-width:1920px) and (max-width:2047px) {
  .view-card-height {
    height: 640px;
  }
}
@media screen and (min-width:1261px) and (max-width:1919px) {
  .view-card-height {
    height: 640px;
  }
}
</style>
