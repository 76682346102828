<template>
  <v-container>
    <Breadcrumbs :breadcrumbs="breadcrumbs"/>
    <v-row class="row mb-0 mt-1">
      <v-col class="col-12 ma-0 row ">
        <v-select style="max-width: 150px;"
                  :items="user_items"
                  v-model="media_id"
                  label="매체"
                  item-text="name"
                  item-value="id"
                  @change="medias"
                  hide-details
        ></v-select>
      </v-col>
    </v-row>
    <v-divider class="indigo mt-2 mb-4"></v-divider>
    <v-row v-if="loading" class="justify-center"
           :loading="loading"
    >
      <spinner :loading="loading"></spinner>
    </v-row>
    <v-row class="mb-0 mt-4">
      <v-col lg="12" class="justify-center pb-0 mb-0" style="padding-top:0px;">
        <div class="font-weight-bold">전체 개수<span class="pl-2" style="color: gray;font-size: 12px;">카드를 클릭하면 플랫폼 별 누적 개수가 변경됩니다.</span></div>
      </v-col>
    </v-row>
    <v-row class="mb-0">
      <v-col lg="3" class="justify-center" style="max-height: 100px;">
        <v-card @click="platformChange(0);">
          <v-card-text class="justify-center" style="text-align: -webkit-center; padding-top:0px; padding-bottom:0px;">
            <v-list-item-content>
              <v-card-title style="font-size: 1rem !important;">
                <v-icon color="rgb(34, 187, 51)" class="pa-2" style="background-color: rgba(34, 187, 51, 0.1);border-radius: 13px;" >mdi-view-dashboard</v-icon>
                <strong class="ml-3" style="color: rgb(34, 187, 51);">수집</strong> <span class="ml-2">{{ this.numberComma(this.inactive) }}개</span>
              </v-card-title>
            </v-list-item-content>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col lg="3" class="justify-center"
      >
        <v-card @click="platformChange(1);">
          <v-card-text class="justify-center" style="text-align: -webkit-center; padding-top:0px; padding-bottom:0px;">
            <v-list-item-content>
              <v-card-title style="font-size: 1rem !important;">
                <v-icon color="rgb(35, 142, 219)" class="pa-2" style="background-color: rgba(35, 142, 219, 0.1);border-radius: 13px;" >mdi-format-list-bulleted</v-icon>
                <strong class="ml-3" style="color: rgb(35, 142, 219);">게시</strong> <span class="ml-2">{{ this.numberComma(this.active) }}개</span>
              </v-card-title>
            </v-list-item-content>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col lg="3" class="justify-center"
      >
        <v-card @click="platformChange(3);">
          <v-card-text class="justify-center" style="text-align: -webkit-center; padding-top:0px; padding-bottom:0px;">
            <v-list-item-content>
              <v-card-title style="font-size: 1rem !important;">
                <v-icon color="rgb(240, 173, 78)" class="pa-2" style="background-color: rgba(240, 173, 78, 0.1);border-radius: 13px;" >mdi-comment-alert-outline</v-icon>
                <strong class="ml-3" style="color: rgb(240, 173, 78);">신고</strong> <span class="ml-2">{{ this.numberComma(this.report) }}개</span>
              </v-card-title>
            </v-list-item-content>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col lg="3" class="justify-center"
      >
        <v-card @click="platformChange(2);">
          <v-card-text class="justify-center" style="text-align: -webkit-center; padding-top:0px; padding-bottom:0px;">
            <v-list-item-content>
              <v-card-title style="font-size: 1rem !important;">
                <v-icon color="rgb(218, 33, 36)" class="pa-2" style="background-color: rgba(218, 33, 36, 0.1);border-radius: 13px;" >mdi-block-helper</v-icon>
                <strong class="ml-3" style="color: rgb(218, 33, 36);">삭제</strong> <span class="ml-2">{{ this.numberComma(this.reportBlock) }}개</span>
              </v-card-title>
            </v-list-item-content>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="mb-0 mt-4">
      <v-col lg="12" class="justify-center pb-0 mb-0" style="padding-top:0px;">
        <div class="font-weight-bold"><span v-bind:style="{ color: this.state_color }">{{ this.state_name }}</span> 플랫폼 별 전체 개수</div>
      </v-col>
    </v-row>
    <v-row class="mb-0">
      <v-card width="100%" class="ma-3">
        <v-card-text class="justify-center" style="text-align: -webkit-center; padding-top:0px; padding-bottom:0px;">
          <v-list-item-content width="80%">
            <v-card-title style="font-size: 1rem !important;">
              <div class="justify-center"
                   v-for="platform in platform_items"
                   :key="platform.value"
                   style="width: 16.6%;"
              >
                <v-icon class="pa-2" style="background-color: rgba(138,138,138,0.1);border-radius: 13px;" >{{ platform.icon }}</v-icon>
                <p class="mt-2 mb-0">{{ platform.platform_kr }}</p>
                <p class="mt-0 mb-0 subtitle-2">{{ platform.count}}</p>
              </div>
            </v-card-title>
          </v-list-item-content>
        </v-card-text>
      </v-card>
    </v-row>

    <v-row class="mb-0 mt-4">
      <v-col lg="4" class="justify-center pb-0 mb-0" style="padding-top:0px;">
        <div class="font-weight-bold"><span v-bind:style="{ color: this.state_color }">{{ this.state_name }}</span> 플랫폼 별 일간 개수</div>
      </v-col>
      <v-col lg="8" class="justify-end row pb-0 mb-0" style="padding-top:0px;">
        <v-menu
            v-model="datepicker"
            :close-on-content-click="false"
        >
          <template v-slot:activator="{ on }">
            <v-text-field class="row-pointer mr-2"
                          style="max-width: 110px;"
                          label="시작일"
                          append-icon="mdi-calendar"
                          v-on="on"
                          offset-y
                          :value="dateRanges[0]"
                          readonly
                          hide-details
            >
            </v-text-field>
          </template>
          <v-date-picker
              v-model="dateRanges[0]"
              no-title
              locale="ko-kr"
              changeMonth="true"
              :max="today"
              @input="dateSearchChange"
          />
        </v-menu>
        <p style="margin-top: 20px !important;">~</p>
        <v-menu
            v-model="end_datepicker"
            :close-on-content-click="false"
        >
          <template v-slot:activator="{ on }">
            <v-text-field class="row-pointer ml-2 mr-2"
                          style="max-width: 110px;"
                          label="종료일"
                          append-icon="mdi-calendar"
                          v-on="on"
                          offset-y
                          :value="dateRanges[1]"
                          readonly
                          hide-details
            >
            </v-text-field>
          </template>
          <v-date-picker
              v-model="dateRanges[1]"
              no-title
              locale="ko-kr"
              changeMonth="true"
              :max="today"
              @input="dateSearchChange"
          />
        </v-menu>
      </v-col>
    </v-row>
    <v-row class="mb-0 mt-0">
      <v-card width="98%" class="ma-3">
          <v-data-table
              :headers="daily_headers"
              :items="daily_items"
              class="elevation-1"
              :items-per-page="5"
              :page.sync="page"
              :footer-props="{
                 'items-per-page-text':'행 개수 : ',
                'items-per-page-options': [5, 10, 15, 20, 25]
              }"
          >
          </v-data-table>
      </v-card>
    </v-row>
    <v-row>
      <v-col class="col-12 ma-0 row justify-end">
        <v-btn-toggle
            v-model="dateChoice"
            class="mt-3 mr-3"
            color="deep-indigo accent-3"
        >
          <v-btn style="height: 40px;" value="daily">
            <span class="hidden-sm-and-down">일간</span>
          </v-btn>
          <v-btn style="height: 40px;" value="weekly">
            <span class="hidden-sm-and-down">주간</span>
          </v-btn>
          <v-btn style="height: 40px;" value="monthly" >
            <span class="hidden-sm-and-down">월간</span>
          </v-btn>
          <v-btn style="height: 40px;" value="custom" >
            <span class="hidden-sm-and-down">기간</span>
          </v-btn>
        </v-btn-toggle>
        <v-menu
            v-model="datepickerForIsActive"
            :close-on-content-click="false"
        >
          <template v-slot:activator="{ on }">
            <v-text-field class="row-pointer mr-2"
                          style="max-width: 110px;"
                          label="시작일"
                          append-icon="mdi-calendar"
                          v-on="on"
                          offset-y
                          :value="dateRanges[2]"
                          readonly
                          hide-details
                          :disabled="!isCustom"
            >
            </v-text-field>
          </template>
          <v-date-picker
              v-model="dateRanges[2]"
              no-title
              locale="ko-kr"
              changeMonth="true"

              @input="activeDateSearchChange"
          /><!-- :max="" 추가해야함 -->
        </v-menu>
        <p style="margin-top: 20px !important;">~</p>
        <v-menu
            v-model="end_datepickerForIsActive"
            :close-on-content-click="false"
        >
          <template v-slot:activator="{ on }">
            <v-text-field class="row-pointer ml-2 mr-2"
                          style="max-width: 110px;"
                          label="종료일"
                          append-icon="mdi-calendar"
                          v-on="on"
                          offset-y
                          :value="dateRanges[3]"
                          readonly
                          hide-details
                          :disabled="!isCustom"
            >
            </v-text-field>
          </template>
          <v-date-picker
              v-model="dateRanges[3]"
              no-title
              locale="ko-kr"
              changeMonth="true"

              @input="activeDateSearchChange"
          /><!-- :max="" 추가해야함 -->
        </v-menu>
      </v-col>
    </v-row>
    <v-row class="mb-0">
      <v-col lg="6" class="justify-center pb-0 mb-0" style="padding-top:0px;">
        <div class="font-weight-bold">
          활성 개수
          <span v-if = "dateChoice !== 'custom'" class="pl-2" style="color: gray;font-size: 12px;">
            {{ this.firstIsActiveName }},{{ this.secondIsActiveName }} 게시한 컨텐츠의 개수입니다.
          </span>
          <span v-else class="pl-2" style="color: gray;font-size: 12px;">
            {{ this.firstIsActiveName }} 게시한 컨텐츠의 개수입니다.
          </span>
        </div>
      </v-col>
      <v-col lg="6" class="justify-center pb-0 mb-0" style="padding-top:0px;">
        <div class="font-weight-bold">
          비활성 개수
          <span v-if = "dateChoice !== 'custom'" class="pl-2" style="color: gray;font-size: 12px;">
            {{ this.firstIsActiveName }},{{ this.secondIsActiveName }} 게시를 취소한 컨텐츠의 개수입니다.
          </span>
          <span v-else class="pl-2" style="color: gray;font-size: 12px;">
            {{ this.firstIsActiveName }} 게시를 취소한 컨텐츠의 개수입니다.
          </span>
        </div>
      </v-col>
    </v-row>
    <v-row class="mb-0">
      <v-col lg="6" class="justify-center"
      >
        <v-card>
          <v-card-text class="justify-center" style="text-align: -webkit-center;">
            <v-list-item-content width="80%">
              <v-card-title v-if="dateChoice !== 'custom'"  style="font-size: 1rem !important; padding-top:0px; padding-bottom:0px;">
                <div class="justify-center" style="width: 50%;">
                  <strong style="color: rgb(1, 142, 223);">{{ this.firstIsActiveName }} 활성화 한 개수</strong>
                  <p class="mb-0">{{ this.numberComma(this.todayActive) }}개</p>
                </div>
                <v-divider inset vertical></v-divider>
                <div class="justify-center" style="width: 50%;">
                  <strong style="color: rgb(12, 2, 165)">{{ this.secondIsActiveName }} 활성화 한 개수</strong>
                  <p class="mb-0">{{ this.numberComma(this.yesterdayActive) }}개</p>
                </div>
              </v-card-title>
              <v-card-title v-else style="font-size: 1rem !important; padding-top:0px; padding-bottom:0px;">
                <div class="justify-center" style="width: 99%;">
                  <strong style="color: rgb(1, 142, 223);">{{ this.firstIsActiveName }} 활성화 한 개수</strong>
                  <p class="mb-0">{{ this.numberComma(this.todayActive) }}개</p>
                </div>
              </v-card-title>
            </v-list-item-content>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col lg="6" class="justify-center"
      >
        <v-card>
          <v-card-text class="justify-center" style="text-align: -webkit-center;">
            <v-list-item-content width="80%">
              <v-card-title v-if="dateChoice !== 'custom'" style="font-size: 1rem !important; padding-top:0px; padding-bottom:0px;">
                <div class="justify-center" style="width: 50%;">
                  <strong style="color: rgb(1, 142, 223);">{{ this.firstIsActiveName }} 비활성 개수</strong>
                  <p class="mb-0">{{ this.numberComma(this.todayInactive) }}개</p>
                </div>
                <v-divider inset vertical></v-divider>
                <div class="justify-center" style="width: 50%;">
                  <strong style="color: rgb(12, 2, 165)">{{ this.secondIsActiveName }} 비활성 개수</strong>
                  <p class="mb-0">{{ this.numberComma(this.yesterdayInactive) }}개</p>
                </div>
              </v-card-title>
              <v-card-title v-else style="font-size: 1rem !important; padding-top:0px; padding-bottom:0px;">
                <div class="justify-center" style="width: 99%;">
                  <strong style="color: rgb(12, 2, 165);">{{ this.firstIsActiveName }} 비활성 개수</strong>
                  <p class="mb-0">{{ this.numberComma(this.todayInactive) }}개</p>
                </div>
              </v-card-title>
            </v-list-item-content>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Breadcrumbs from "../../components/Breadcrumbs";
import Spinner from "../../components/Spinner";
import {mapGetters} from "vuex";
import numeral from 'numeral';
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import dayjs from "dayjs";

am4core.useTheme(am4themes_animated);

export default {
  components: {
    Breadcrumbs,
    Spinner,
  },
  data() {
    return {
      loading: false,
      tag_loading: false,
      breadcrumbs: [
        {
          text: '수집/게시/신고 개수',
          disabled: false,
          href: '/statistics',
        },
      ],
      daily_items: [],
      daily_headers: [
        {text: '수집일', value: 'date', width: '82px', align: 'center'},
        {text: '인스타그램',  value: 'instagram', width: '160px', align: 'center'},
        {text: '유튜브', value: 'youtube', width: '160px', align: 'center'},
        {text: '네이버블로그', value: 'naver_blog', width: '160px', align: 'center'},
        {text: '구글뉴스', value: 'google_news', width: '160px', align: 'center'},
        {text: '트위터', value: 'twitter', width: '160px', align: 'center'},
        {text: '네이버뉴스', value: 'naver_news', width: '160px', align: 'center'},
        {text: '합계', value: 'daily_total', width: '160px', align: 'center'},
      ],
      platform_items: [
          {platform: 'instagram', platform_kr: '인스타그램', icon: 'mdi-instagram', count: 0 },
          {platform: 'youtube', platform_kr: '유튜브', icon: 'mdi-youtube', count: 0 },
          {platform: 'naver-blog', platform_kr: '네이버블로그', icon: 'mdi-blogger', count: 0 },
          {platform: 'google-news', platform_kr: '구글뉴스', icon: 'mdi-google', count: 0 },
          {platform: 'twitter', platform_kr: '트위터', icon: 'mdi-twitter', count: 0 },
          {platform: 'naver-news', platform_kr: '네이버뉴스', icon: 'mdi-checkbox-blank', count: 0 }
      ],
      user_items: [],
      state: 0,
      media_id: null,
      state_name: '수집',
      state_color: 'rgb(34, 187, 51)',
      inactive: '', // 비활성(수집) 수
      active: '', // 활성(게시) 수
      report: '', // 신고 수
      reportBlock: '', // 차단 수
      todayInactive: '', // 오늘 비활성수
      yesterdayInactive: '', // 어제 비활성수
      todayActive: '', // 오늘 활성수
      yesterdayActive: '', // 어제 활성수
      datepicker: false,
      end_datepicker: false,
      dateRanges : [],
      page : 1,
      today : dayjs().format('YYYY-MM-DD'),
      dateChoice : 'daily',
      firstIsActiveName: '오늘',
      secondIsActiveName: '어제',
      datepickerForIsActive: false,
      end_datepickerForIsActive: false,
      isCustom:false,
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
    getMediaId() {
      return this.$store.getters.getMediaId;
    },
  },
  watch: {
    dateRanges: {
      handler() {
        if (this.dateRanges[0] > this.dateRanges[1]) {
          this.dateRanges.reverse();
          this.dateSearchChange();
          this.activeDateSearchChange();
        }
      }
    },
    dateChoice() {
      if (this.dateChoice === 'daily') {
        this.firstIsActiveName = '오늘';
        this.secondIsActiveName = '어제';
        this.isCustom = false;
        this.activeDateSearchChange();
      }
      if (this.dateChoice === 'weekly') {
        this.firstIsActiveName = '이번 주';
        this.secondIsActiveName = '지난주';
        this.isCustom = false;
        this.activeDateSearchChange();
      }
      if (this.dateChoice === 'monthly') {
        this.firstIsActiveName = '이번 달';
        this.secondIsActiveName = '저번달';
        this.isCustom = false;
        this.activeDateSearchChange();
      }
      if (this.dateChoice === 'custom') {
        this.firstIsActiveName = '선택한 기간 동안';
        this.secondIsActiveName = '';
        this.isCustom = true;
        this.activeDateSearchChange();
      }
    },
    media_id() {
      this.$store.commit("SET_MEDIA_ID", this.media_id);
    },
  },
  mounted() {
    this.dateRanges[0] = dayjs(dayjs().format("YYYY-MM-DD")).subtract(30, 'day').format('YYYY-MM-DD');
    this.dateRanges[1] = this.today;
    this.dateRanges[2] = dayjs().format('YYYY-MM-DD');
    this.dateRanges[3] = dayjs().format('YYYY-MM-DD');

    this.media_id = this.getMediaId;
    const mediaId = this.media_id;
    if (mediaId) {
      this.media_id = parseInt(mediaId);
    } else {
      this.media_id = this.user.user_media[0].id;
    }
    if (localStorage.getItem('token')) {
      this.user_items = this.user.user_media;
    }
    this.changeDatas();
  },
  methods: {
    medias(media) {
      this.page = 1;
      this.media_id = media;
      if (typeof this.media_id === "undefined") {
        this.media_id = this.user.user_media[0].id;
      } else {
        this.media_id = media;
      }
      this.changeDatas();
    },
    getTotalCount() {
      this.loading = true;
      let header = this.$root.getMediaEngNameById(this.media_id);
      this.axios.get('api/v1/admin/articles/stats', {
        params: {
          'media_id': parseInt(this.media_id),
          'dateChoice': this.dateChoice,
          'choice_start_date': this.dateRanges[2],
          'choice_end_date': this.dateRanges[3],
        },
        headers: {
          'tv': header
        }
      })
          .then(res => {
            this.inactive = res.data.data.inactive; // 수집 수
            this.active = res.data.data.active; // 게시 수
            this.report = res.data.data.report; // 신고 수
            this.reportBlock = res.data.data.reportBlock; // 차단 수
            this.todayInactive = res.data.data.todayInactive; // 오늘 비활성 수
            this.yesterdayInactive = res.data.data.yesterdayInactive; // 어제 비활성 수
            this.todayActive = res.data.data.todayActive; // 오늘 활성 수
            this.yesterdayActive = res.data.data.yesterdayActive; // 어제 활성 수

          })
          .catch(err => {
            this.loading = false;
            console.error(err);
          });
      this.loading = false;
    },
    getPlatformCount() {
      let result = [];
      let isset_twitter = false;
      let header = this.$root.getMediaEngNameById(this.media_id);
      this.axios.get('api/v1/admin/articles/platform-stats', {
        params: {
          'media_id': parseInt(this.media_id),
          'state': this.state,
        },
        headers: {
          'tv': header
        }
      })
          .then(res => {
              res.data.data.map((item) => {
                let icon = this.$root.getPlatformIconByName(item.platform);

                if(item.platform === 'twitter') isset_twitter = true;

                if(typeof item.cnt === 'undefined') item.cnt = 0;

                result.push({
                    platform: item.platform,
                    platform_kr: item.platform_kr,
                    icon: icon,
                    count: item.cnt,
                })
              })
              if(!isset_twitter){
                  result.push({
                    platform: 'twitter',
                    platform_kr: '트위터',
                    icon: 'mdi-twitter',
                    count: 0,
                  })
              }
              this.platform_items = result;
          })
          .catch(err => {
            console.error(err);
            //location.reload();
          });
    },
    getPlatformReportCount() {
      let result = [];
      let isset_twitter = false;
      let header = this.$root.getMediaEngNameById(this.media_id);

      this.axios.get('api/v1/admin/article-reports/platform-stats', {
        params: {
          'media_id': parseInt(this.media_id),
        },
        headers: {
          'tv': header
        }
      })
          .then(res => {
            res.data.data.map((item) => {
              let icon = this.$root.getPlatformIconByName(item.platform);

              if(item.platform === 'twitter') isset_twitter = true;

              if(typeof item.cnt === 'undefined') item.cnt = 0;

              result.push({
                platform: item.platform,
                platform_kr: item.platform_kr,
                icon: icon,
                count: item.cnt,
              })
            })
            if(!isset_twitter){
              result.push({
                platform: 'twitter',
                platform_kr: '트위터',
                icon: 'mdi-twitter',
                count: 0,
              })
            }
            this.platform_items = result;
          })
          .catch(err => {
            console.error(err);
            //location.reload();
          });
    },
    numberComma(value) {
      return numeral(value).format('0,0');
    },
    platformChange(state){  // state에 따른 플랫폼 별 누적 개수
      this.state = state;
      switch (this.state){
        case 1 :
          this.state_name = '게시';
          this.state_color = 'rgb(35, 142, 219)';
          break;
        case 2 :
          this.state_name = '삭제';
          this.state_color = 'rgb(218, 33, 36)';
          break;
        case 3 :
          this.state_name = '신고';
          this.state_color = 'rgb(240, 173, 78)';
          break;
        default:
          this.state_name = '수집';
          this.state_color = 'rgb(34, 187, 51)';
          break;
      }
      this.changeDatas();
    },
    activeCountChange() {
      switch(this.dateChoice) {
        case 'daily':
          break;
        case 'weekly':
          break;
        case 'monthly':
          break;
        case 'custom':
          break;
        default:
          break;
      }
    },
    getDailyCount(){ // 수집/게시/신고 플랫폼 별 일간 개수
      this.page = 1;
      let result = [];
      let header = this.$root.getMediaEngNameById(this.media_id);
      this.axios.get('api/v1/admin/articles/platform-daily-stats', {
        params: {
          'media_id': parseInt(this.media_id),
          'state': this.state,
          'start_date': this.dateRanges ? this.dateRanges[0] : '',
          'end_date': this.dateRanges ? this.dateRanges[1] : '',
        },
        headers: {
          'tv': header
        }
      })
          .then(res => {
            res.data.data.map((item) => {
              if(!Object.keys(item).includes('instagram')) item.instagram = 0;
              if(!Object.keys(item).includes('youtube')) item.youtube = 0;
              if(!Object.keys(item).includes('naverBlog')) item.naverBlog = 0;
              if(!Object.keys(item).includes('twitter')) item.twitter = 0;
              if(!Object.keys(item).includes('googleNews')) item.googleNews = 0;
              if(!Object.keys(item).includes('naverNews')) item.naverNews = 0;

              let daily_total = item.instagram + item.youtube + item.naverBlog + item.twitter + item.googleNews + item.naverNews;
              result.push({
                date: item.date.substr(5),
                daily_total: this.numberComma(daily_total)+'개',
                instagram: this.numberComma(item.instagram)+'개',
                youtube: this.numberComma(item.youtube)+'개',
                naver_blog: this.numberComma(item.naverBlog)+'개',
                twitter: this.numberComma(item.twitter)+'개',
                google_news: this.numberComma(item.googleNews)+'개',
                naver_news: this.numberComma(item.naverNews)+'개',
              })
            })
            this.daily_items = result;

          })
          .catch(err => {
            console.error(err);
            //location.reload();
          });
    },
    getDailyReportCount(){
      this.page = 1;
      let result = [];
      let header = this.$root.getMediaEngNameById(this.media_id);
      this.axios.get('api/v1/admin/article-reports/platform-daily-stats', {
        params: {
          'media_id': parseInt(this.media_id),
          'start_date': this.dateRanges ? this.dateRanges[0] : '',
          'end_date': this.dateRanges ? this.dateRanges[1] : '',
        },
        headers: {
          'tv': header
        }
      })
          .then(res => {
            res.data.data.map((item) => {
              if(!Object.keys(item).includes('instagram')) item.instagram = 0;
              if(!Object.keys(item).includes('youtube')) item.youtube = 0;
              if(!Object.keys(item).includes('naverBlog')) item.naverBlog = 0;
              if(!Object.keys(item).includes('twitter')) item.twitter = 0;
              if(!Object.keys(item).includes('googleNews')) item.googleNews = 0;
              if(!Object.keys(item).includes('naverNews')) item.naverNews = 0;
              let daily_total = item.instagram + item.youtube + item.naverBlog + item.twitter + item.googleNews + item.naverNews;
              result.push({
                date: item.date.substr(5),
                daily_total: daily_total+'개',
                instagram: item.instagram+'개',
                youtube: item.youtube+'개',
                naver_blog: item.naverBlog+'개',
                twitter: item.twitter+'개',
                google_news: item.googleNews+'개',
                naver_news: item.naverNews+'개',
              })
            })
            this.daily_items = result;

          })
          .catch(err => {
            console.error(err);
            //location.reload();
          });
    },

    dateSearchChange() {
      this.page = 1;
      if (this.dateRanges[0] && this.dateRanges[1]) {
        this.changeDatas();
      } else {
        if(this.dateRanges[0]){
          this.changeDatas();
          this.dateRanges[1] = this.today;
        }else{
          this.$Swal.fire({
            icon: 'error',
            text: '시작 날짜를 선택해주세요.',
          }).then((result) => {
            if (result.isConfirmed) {
              location.reload();
            }
          }).catch(err => {
            console.error(err);
          });
        }
      }
      this.datepicker = false;
      this.end_datepicker = false;
    },
    activeDateSearchChange() {
      if (this.dateRanges[2] && this.dateRanges[3]) {
        this.changeDatas();
      } else {
        if(this.dateRanges[2]){
          this.changeDatas();
          this.dateRanges[3] = this.today;
        }else{
          this.$Swal.fire({
            icon: 'error',
            text: '시작 날짜를 선택해주세요.',
          }).then((result) => {
            if (result.isConfirmed) {
              location.reload();
            }
          }).catch(err => {
            console.error(err);
          });
        }
      }

      this.datepickerForIsActive = false;
      this.end_datepickerForIsActive = false;
    },
    changeDatas(){
      this.getTotalCount();

      if(this.state === 3) {
        this.getPlatformReportCount();
        this.getDailyReportCount();
      } else {
        this.getPlatformCount();
        this.getDailyCount();
      }
    },
  }
}
</script>

<style scoped>

</style>
