<template>
  <v-container>
    <Breadcrumbs :breadcrumbs="breadcrumbs"/>
    <v-row class="row mb-0 mt-1">
      <v-col class="col-4 ma-0 row ">
        <v-select style="max-width: 150px;"
                  :items="user_items"
                  v-model="media_id"
                  label="매체"
                  item-text="name"
                  item-value="id"
                  @change="medias"
                  hide-details
        ></v-select>
      </v-col>
      <v-col class="col-8 ma-0 row justify-end ">
        <v-select style="max-width: 150px;" class="mr-3"
                  :items="sort_items"
                  v-model="sort"
                  label="정렬 기준"
                  item-text="sort"
                  item-value="value"
                  @change="sortChange"
                  hide-details
        ></v-select>
        <v-btn-toggle
            v-model="dateChoice"
            class="mt-3 mr-3"
            color="deep-indigo accent-3"

        >
          <v-btn
              style="height: 40px;"
              value="daily"
          >
            <span class="hidden-sm-and-down">일간</span>
          </v-btn>

          <v-btn
              style="height: 40px;"
              value="weekly"
          >
            <span class="hidden-sm-and-down">주간</span>
          </v-btn>
          <v-btn
              style="height: 40px;"
              value="monthly"
          >
            <span class="hidden-sm-and-down">월간</span>
          </v-btn>
        </v-btn-toggle>
        <v-menu
            v-model="datepicker"
            :close-on-content-click="false"
        >
          <template v-slot:activator="{ on }">
            <v-text-field class="row-pointer mr-2"
                          style="max-width: 110px;"
                          label="시작일"
                          append-icon="mdi-calendar"
                          v-on="on"
                          offset-y
                          :value="dateRanges[0]"
                          readonly
                          hide-details
            >
            </v-text-field>
          </template>
          <v-date-picker
              v-model="dateRanges[0]"
              no-title
              locale="ko-kr"
              changeMonth="true"
              :max="today"
              @input="dateSearchChange"
          />
        </v-menu>
        <p style="margin-top: 20px !important;">~</p>
        <v-menu
            v-model="end_datepicker"
            :close-on-content-click="false"
        >
          <template v-slot:activator="{ on }">
            <v-text-field class="row-pointer ml-2 mr-2"
                          style="max-width: 110px;"
                          label="종료일"
                          append-icon="mdi-calendar"
                          v-on="on"
                          offset-y
                          :value="dateRanges[1]"
                          readonly
                          hide-details
            >
            </v-text-field>
          </template>
          <v-date-picker
              v-model="dateRanges[1]"
              no-title
              locale="ko-kr"
              changeMonth="true"
              :max="today"
              @input="dateSearchChange"
          />
        </v-menu>
      </v-col>
    </v-row>
    <v-divider class="indigo mt-2 mb-4"></v-divider>
    <v-row v-if="loading" class="justify-center"
           :loading="loading"
    >
      <spinner :loading="loading"></spinner>
    </v-row>
    <v-row class="mb-0">
      <v-col lg="12" class="justify-center pb-0 mb-0">
        <v-row class="ma-0">
          <v-col class="row pb-0 mb-0 pt-0 pr-0">
            <div class="font-weight-bold" style="margin-top: 2%;">콘텐츠 순위<span class="pl-2" style="color: gray;font-size: 12px;">순위는 100위까지 보여주며, <span style="text-decoration: underline; color: #3b3b3b;">회원/비회원</span>이 유입된 콘텐츠를 집계하여 순위를 보여줍니다. 인스타그램은 콘텐츠 영역에 내용이, 그 외 플랫폼은 제목이 나옵니다.</span></div>
            <v-spacer></v-spacer>
          </v-col>
        </v-row>

      </v-col>
    </v-row>
    <v-row class="mb-0 mt-0">
      <v-card width="100%" class="ma-3 elevation-0">
        <v-data-iterator
                :items="article_pv_items"
                :items-per-page.sync="per_page"
                :page.sync="page"
                hide-default-footer
            >
              <template v-slot:default="props">
                <v-row>
                  <v-col
                      v-for="item in props.items"
                      :key="item.dateType"
                      cols="12"
                      sm="6"
                      md="6"
                      lg="6"
                  >
                    <!-- <v-card class="elevation-2" style="min-height: 720px; max-height: 720px; overflow-y: auto;overflow-x: hidden;"> -->
                    <v-card
                        class="elevation-2"
                        style="overflow-y: auto;overflow-x: hidden;"
                        :min-height="respHeight"
                        :max-height="respHeight"
                    >
                      <v-card-title class="subheading font-weight-bold">
                        {{ item.dateType }}
                        <span v-if="dateChoice === 'monthly'">
                          월
                        </span>
                      </v-card-title>
                      <v-divider></v-divider>
                      <v-card-subtitle class="subheading font-weight-bold row">
                        <span style="width: 15%;" class="text-center">순위</span>
                        <span style="width: 15%;" class="text-center">플랫폼</span>
                        <span style="width: 49%;" class="text-center">콘텐츠</span>
                        <span style="width: 10%;" class="text-center">
                          <span v-if="sort === 'uv'" style="color: #283593;">UV</span>
                          <span v-else>UV</span>
                        </span>
                        <span style="width: 10%;" class="text-center">
                          <span v-if="sort === 'pv'" style="color: #283593;">PV</span>
                          <span v-else>PV</span>
                        </span>
                      </v-card-subtitle>
                      <v-divider></v-divider>

                      <div v-if="item.articles && item.articles['total_count'] !== item.articles['null_count']">
                        <div v-for="(article) in item.articles" :key="article.article_id">
                        <v-list dense
                          v-if="article.article !== null && article.rank < 101"
                        >
                          <v-list-item
                            @click="moreView(article.article)"
                          >
                            <span style="width: 15%;" class="text-center text-subtitle-2">
                              <v-icon v-if="article.rank === 1" color="rgb(255 190 0)">mdi-crown-outline</v-icon>
                              {{ article.rank }}위
                            </span>
                            <span style="width: 15%;" class="text-left text-subtitle-2">
                              <span v-if="article.article.platform === 'instagram'">
                                  <v-icon class="mr-2"
                                          color="purple"
                                  >mdi-instagram</v-icon>
                                  인스타그램
                                </span>
                                <span v-if="article.article.platform === 'youtube'">
                                  <v-icon class="mr-2"
                                          color="red"
                                  >mdi-youtube</v-icon>
                                  유튜브
                                </span>
                                <span v-if="article.article.platform === 'naver-blog'">
                                  <v-icon class="mr-2"
                                          color="green"
                                  >mdi-blogger</v-icon>
                                  네이버블로그
                                </span>
                              <span v-if="article.article.platform === 'naver-news'">
                                  <v-icon class="mr-2"
                                          color="green"
                                  >mdi-alpha-n-box</v-icon>
                                  네이버뉴스
                                </span>
                                <span v-if="article.article.platform === 'twitter'">
                                  <v-icon class="mr-2"
                                          color="blue"
                                  >mdi-twitter</v-icon>
                                  트위터
                                </span>
                                <span v-if="article.article.platform === 'google-news'">
                                  <v-icon class="mr-2"
                                          color="blue"
                                  >mdi-google</v-icon>
                                  구글뉴스
                                </span>
                            </span>
                            <span style="width: 50%;" class="text-left text-subtitle-2">{{ article.article.title ? article.article.title.substr(0, 50) : article.article.contents.substr(0, 50) }}</span>
                            <span style="width: 10%;" class="text-center text-subtitle-2">{{ article.uv_count }}번</span>
                            <span style="width: 10%;" class="text-center text-subtitle-2">{{ article.count }}번</span>
                          </v-list-item>
                          <v-divider></v-divider>
                        </v-list>
                        </div>
                      </div>
                      <div v-else>
                        <v-list dense>
                          <v-list-item>
                            <span style="width: 100%;padding-top: 35%;" class="text-center">해당 {{ date_type_text }}에는 데이터가 없습니다.</span>
                          </v-list-item>
                        </v-list>
                      </div>
                    </v-card>
                  </v-col>
                </v-row>
              </template>
          <template v-slot:footer>
            <v-row
                class="mt-4 mr-1"
                align="end"
                justify="end"
            >
              <span
                  class="mr-4 mt-4 grey--text"
              >
                현재 페이지 : {{ page }} / 전체 페이지 : {{ articlePvNumberOfPages }}
          </span>
              <v-btn
                  fab
                  small
                  dark
                  color="rgb(56 71 179)"
                  class="mr-1"
                  @click="articlePvFormerPage"
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn
                  fab
                  small
                  dark
                  color="rgb(56 71 179)"
                  class="ml-1"
                  @click="articlePvNextPage"
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </v-row>
          </template>
            </v-data-iterator>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import Breadcrumbs from "../../components/Breadcrumbs";
import Spinner from "../../components/Spinner";
import {mapGetters} from "vuex";
import numeral from 'numeral';
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import dayjs from "dayjs";

am4core.useTheme(am4themes_animated);

export default {
  components: {
    Breadcrumbs,
    Spinner,
  },
  data() {
    return {
      loading: false,
      breadcrumbs: [
        {
          text: '콘텐츠 순위',
          disabled: false,
          href: '/statistic-content-ranks',
        },
      ],
      article_pv_length: 0,
      top_count: 10,
      per_page: 2,
      page: 1,
      article_pv_items: [],
      user_items: [],
      media_id: null,
      datepicker: false,
      end_datepicker: false,
      dateRanges : [],
      sort: 'uv',
      sort_items: [
            {sort: 'PV 많은 순', value: 'pv'},
            {sort: 'UV 많은 순', value: 'uv'},
          ],
      dateChoice : '',
      date_type_text : '일',
      today : dayjs(dayjs().format("YYYY-MM-DD")).format('YYYY-MM-DD'),
      respHeight: 700,
      is_media_change: false,
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
    getPage() {
      return this.$store.getters.getPage;
    },
    getMediaId() {
      return this.$store.getters.getMediaId;
    },
    // getDateRanges() {
    //   return this.$store.getters.getDateRanges;
    // },
    getDataChoice() {
      return this.$store.getters.getDateChoice;
    },
    articlePvNumberOfPages () {
      return Math.ceil(this.article_pv_items.length / this.per_page)
    },
  },
  watch: {
    dateRanges: {
      handler() {
        this.$store.commit("SET_DATERANGES", this.dateRanges);
        if(!this.dateRanges[0] && this.dateRanges[1]){
          this.$store.commit("SET_DATERANGES", []);
        }
        if (this.dateRanges[0] > this.dateRanges[1]) {
          this.dateRanges.reverse();
          this.dateSearchChange();
        }
        // if(this.getDateRanges[0] !== this.dateRanges[0] || this.getDateRanges[1] !== this.dateRanges[1]) {
        //   if(!this.dateRanges[0])  this.dateRanges[0] = this.getDateRanges[0];
        //   if(!this.dateRanges[1])  this.dateRanges[1] = this.getDateRanges[1];
        // }
      }
    },
    page() {
      this.$store.commit("SET_PAGE", this.page);
    },
    media_id() {
      this.is_media_change = true;

      this.$store.commit("SET_MEDIA_ID", this.media_id);

    },
    dateChoice(){
      this.$store.commit("SET_DATECHOICE", this.dateChoice);
      this.$store.commit("SET_DATERANGES", this.dateRanges);
      if(this.dateChoice === 'daily'){
        this.date_type_text = '일';
        this.dateRanges[0] = dayjs(dayjs().format("YYYY-MM-DD")).subtract(3, 'day').format('YYYY-MM-DD');
        this.dateSearchChange();
      }
      if(this.dateChoice === 'weekly'){
        this.date_type_text = '주';
        this.dateRanges[0] = dayjs(dayjs().format("YYYY-MM-DD")).subtract(14, 'week').format('YYYY-MM-DD');
        this.dateSearchChange();
      }
      if(this.dateChoice === 'monthly'){
        this.date_type_text = '월';
        this.dateRanges[0] = dayjs(dayjs().format("YYYY-MM-DD")).subtract(14, 'month').format('YYYY-MM-DD');
        this.dateSearchChange();
      }
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
    window.dispatchEvent(new Event('resize'));
    this.dateRanges[0] = dayjs(dayjs().format("YYYY-MM-DD")).subtract(3, 'day').format('YYYY-MM-DD');
    this.dateRanges[1] = this.today;

    const mediaId = this.media_id;
    if (mediaId) {
      this.media_id = parseInt(mediaId);
    } else {
      this.media_id = this.user.user_media[0].id;
    }

    if(this.getMediaId && this.getMediaId !== this.media_id) this.media_id = this.getMediaId;

    if(this.getPage !== 1) this.page = this.getPage;
    if(this.getPage === 1 && this.page !== this.getPage) this.page = 1;
    // if(this.getDateRanges && this.getDateRanges !== this.dateRanges) this.dateRanges = this.getDateRanges;
    if(this.getDataChoice && this.getDataChoice !== this.dateChoice) this.dateChoice = this.getDataChoice;
    if(!this.dateChoice) this.dateChoice = 'daily';

    if(!this.dateRanges[0] && this.dateRanges[1]){
      this.$store.commit("SET_DATERANGES", []);
    }

    if (localStorage.getItem('token')) {
      this.user_items = this.user.user_media;
    }
    //this.changeDatas();

  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      //this.windowWidth = window.innerWidth;
      this.respHeight = window.innerHeight - 380;
    },

    articlePvNextPage () {
      if (this.page + 1 <= this.articlePvNumberOfPages) this.page += 1
    },
    articlePvFormerPage () {
      if (this.page - 1 >= 1) this.page -= 1
    },
    medias(media) {
      this.changePages();
      this.media_id = media;
      if (typeof this.media_id === "undefined") {
        this.media_id = this.user.user_media[0].id;
      } else {
        this.media_id = media;
      }
      this.changeDatas();
    },
    numberComma(value) {
      return numeral(value).format('0,0');
    },
    getArticlePageViewDailyCount(){ // 콘텐츠 순위 (PV)
      let header = this.$root.getMediaEngNameById(this.media_id);
      let result = [];
      this.loading = true;
      this.axios.get('api/v1/admin/articles/page-view-stats', {
        params: {
          'media_id': parseInt(this.media_id),
          'start_date': this.dateRanges ? this.dateRanges[0] : '',
          'end_date': this.dateRanges ? this.dateRanges[1] : '',
          'date_choice': this.dateChoice ? this.dateChoice : 'daily',
          'sort': this.sort,
        },
        headers: {
          'TV': header
        }
      })
          .then(res => {
            this.loading = false;
            res.data.data.data.map((item) => {
              // 순위 추가
              if(typeof item.articles !== 'undefined'){
                let keys = Object.keys(item.articles);
                // eslint-disable-next-line no-unused-vars
                let null_count = 0;
                for (let i = 0; i < keys.length; i++) {
                  if(item.articles[keys[i]]['article'] === null && item.articles[keys[i]]['posted_article'] === null){
                    null_count++;
                  }else{
                    item.articles[keys[i]]['rank'] = i+1-null_count;
                  }
                  item.articles['null_count'] = null_count;
                  item.articles['total_count'] = keys.length;
                }
              }
              let dateType = item.dateType;
              if(this.dateChoice === 'daily') dateType = item.dateType+"("+this.getDay(item.dateType)+")";
              result.push({
                dateType: dateType,
                articles: item.articles,
                article_count: item.article_count - item.article_count + 1,
              })
            })
            this.article_pv_items = result;
            this.article_pv_length = res.data.data.total;

            this.loading = false;
          })
          .catch(err => {
            console.error(err);
            // location.reload();
          });
    },
    dateSearchChange() {
      this.changePages();
      if (this.dateRanges[0] && this.dateRanges[1]) {
        this.changeDatas();
      } else {
        if(this.dateRanges[0]){
          this.changeDatas();
          this.dateRanges[1] = this.today;
        }else{
          this.$Swal.fire({
            icon: 'error',
            text: '시작 날짜를 선택해주세요.',
          }).then((result) => {
            if (result.isConfirmed) {
              location.reload();
            }
          }).catch(err => {
            console.error(err);
          });
        }
      }
      this.datepicker = false;
      this.end_datepicker = false;
    },
    changeDatas(){
      this.getArticlePageViewDailyCount();
    },
    changePages(){
      this.page = 1;
    },
    moreView(value) {
      let header = this.$root.getMediaEngNameById(this.media_id);
      let url = `&page=${this.page}&per_page=${this.per_page}&media_id=${this.media_id}&start_date=${this.dateRanges[0] ?? ''}&end_date=${this.dateRanges[1] ?? ''}`;
      this.$router.push('/'+value.id+'?c9='+header+url);
    },
    getDay(date){
      const week = ['일', '월', '화', '수', '목', '금', '토'];
      return week[new Date(date).getDay()];
    },
    sortChange(value) {
      this.sort = value;
      this.changeDatas();
    },
  }
}
</script>

<style scoped>

</style>
