import axios from 'axios'
import store from '../store'

export default {
	namespaced: true,

	state: {
		token: null,
		user: null,
		grade: 0,
	},

	getters: {
		authenticated(state) {
			return state.token
		},

		user(state) {
			return state.user
		},

		grade(state) {
			return state.grade
		}
	},

	mutations: {
		SET_TOKEN(state, token) {
			state.token = token
		},

		SET_USER(state, data) {
			state.user = data
		},

		SET_USER_GRADE(state, data) {
			state.grade = data
		}
	},

	actions: {
		async google_login({dispatch}, token) {
			store.dispatch('setError', false)

			try {
				return dispatch('attempt', token)
			} catch (error) {
				store.dispatch('setError', '로그인 정보를 확인해 주세요.')
			}
		},

		async login({dispatch}, credentials) {
			store.dispatch('setError', false)

			try {
				let response = await axios.post('oauth/token', credentials)
				return dispatch('attempt', response.data.access_token)
			} catch (error) {
				store.dispatch('setError', '로그인 정보를 확인해 주세요.')
			}
		},

		async attempt({commit, state}, token) {
			if (token) {
				commit('SET_TOKEN', token)
			}

			if (!state.token) {
				return
			}

			try {
				let response = await axios.get('api/v1/me')
				commit('SET_USER', response.data.data)
				commit('SET_USER_GRADE', response.data.data.grade ?? 0)
			} catch (error) {
				commit('SET_TOKEN', null)
				commit('SET_USER', null)
				commit('SET_USER_GRADE', 0)
			}
		},

		logout({commit}) {
			commit('SET_TOKEN', null)
			commit('SET_USER', null)
			commit('SET_USER_GRADE', 0)
			localStorage.clear();
		}
	},
}
