<template>
  <v-container>
    <Breadcrumbs :breadcrumbs="breadcrumbs"/>
    <v-row class="row mb-0 mt-1">
      <v-col class="col-4 ma-0 row ">
        <v-select style="max-width: 150px;"
                  :items="user_items"
                  v-model="media_id"
                  label="매체"
                  item-text="name"
                  item-value="id"
                  @change="medias"
                  hide-details
        ></v-select>
      </v-col>
      <v-col class="col-8 ma-0 row justify-end">
        <v-btn-toggle
            v-model="dateChoice"
            class="mt-3 mr-3"
            color="deep-indigo accent-3"
        >
          <v-btn style="height: 40px;" value="daily">
            <span class="hidden-sm-and-down">일간</span>
          </v-btn>
          <v-btn style="height: 40px;" value="weekly">
            <span class="hidden-sm-and-down">주간</span>
          </v-btn>
          <v-btn style="height: 40px;" value="monthly" >
            <span class="hidden-sm-and-down">월간</span>
          </v-btn>
        </v-btn-toggle>
        <v-menu
            v-model="datepicker"
            :close-on-content-click="false"
        >
          <template v-slot:activator="{ on }">
            <v-text-field class="row-pointer mr-2"
                          style="max-width: 110px;"
                          label="시작일"
                          append-icon="mdi-calendar"
                          v-on="on"
                          offset-y
                          :value="dateRanges[0]"
                          readonly
                          hide-details
            >
            </v-text-field>
          </template>
          <v-date-picker
              v-model="dateRanges[0]"
              no-title
              locale="ko-kr"
              changeMonth="true"
              :max="today"
              @input="dateSearchChange"
          />
        </v-menu>
        <p style="margin-top: 20px !important;">~</p>
        <v-menu
            v-model="end_datepicker"
            :close-on-content-click="false"
        >
          <template v-slot:activator="{ on }">
            <v-text-field class="row-pointer ml-2 mr-2"
                          style="max-width: 110px;"
                          label="종료일"
                          append-icon="mdi-calendar"
                          v-on="on"
                          offset-y
                          :value="dateRanges[1]"
                          readonly
                          hide-details
            >
            </v-text-field>
          </template>
          <v-date-picker
              v-model="dateRanges[1]"
              no-title
              locale="ko-kr"
              changeMonth="true"
              :max="today"
              @input="dateSearchChange"
          />
        </v-menu>
      </v-col>
    </v-row>
    <v-divider class="indigo mt-2 mb-4"></v-divider>
    <v-row v-if="loading" class="justify-center" :loading="loading">
      <spinner :loading="loading"></spinner>
    </v-row>
    <v-row class="mb-0">
      <v-col lg="12" class="justify-center pb-0 mb-0">
        <v-row class="ma-0">
          <v-col class="row pb-0 mb-0 pt-0 pr-0">
            <div class="font-weight-bold" style="margin-top: 2%;">UV & PV</div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="mb-0 mt-0">
      <v-card width="100%" class="ma-3">
        <v-card-text class="justify-center" style="text-align: -webkit-center;">
          <v-row no-gutters>
            <div ref="chartUserVisitDailyDiv" style="width: 100%;height: 300px;"></div>
          </v-row>
          <v-row no-gutters>
            <v-card width="100%" class="ma-3 elevation-1">
              <v-data-table
                  :headers="uv_pv_headers"
                  :items="uv_pv_items"
                  class="elevation-0"
                  :items-per-page="5"
                  :page.sync="uv_pv_page"
                  :footer-props="{
                      'items-per-page-text':'행 개수 : ',
                      'items-per-page-options': [5, 10, 15, 20, 25]
                  }"
              >
              </v-data-table>
            </v-card>
          </v-row>
        </v-card-text>
      </v-card>
    </v-row>
    <v-row class="mb-0">
      <v-col lg="12" class="justify-center pb-0 mb-0">
        <v-row class="ma-0">
          <v-col class="row pb-0 mb-0 pt-0 pr-0">
            <div class="font-weight-bold" style="margin-top: 2%;">사용자별 PV
              (<span style="color: #283593;" class="text-center text-subtitle-2"> ■ 회원 </span> /
              <span style="color: rgb(122 121 121);" class="text-center text-subtitle-2">■ 비회원 </span>)
            </div>
            <v-spacer></v-spacer>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="mb-0 mt-0">
      <v-card width="100%" class="ma-3 elevation-0">
        <v-data-iterator
                :items="user_pv_items"
                :items-per-page.sync="user_pv_per_page"
                :page.sync="user_pv_page"
                hide-default-footer
            >
              <template v-slot:default="props">
                <v-row>
                  <v-col
                      v-for="item in props.items"
                      :key="item.dateType"
                      cols="12"
                      sm="6"
                      md="6"
                      lg="3"
                  >
                    <v-card class="elevation-2" style="min-height: 720px; max-height: 720px; overflow-y: auto;overflow-x: hidden;">
                      <v-card-title class="subheading font-weight-bold">
                        {{ item.dateType }}
                        <span v-if="dateChoice === 'monthly'">
                          월
                        </span>
                      </v-card-title>
                      <v-divider></v-divider>
                      <v-card-subtitle class="subheading font-weight-bold row justify-center pl-6" style="width:100%;">
                        <span style="width: 73%;" class="text-left">사용자 아이디</span>
                        <span style="width: 25%;" class="text-left">PV</span>
                      </v-card-subtitle>
                      <v-divider></v-divider>
                      <div v-if="item.users">
                      <v-list
                          dense
                          v-for="user in item.users"
                          :key="user.user_id"
                      >
                        <v-list-item >
                            <span style="width: 70%; color: #283593!important;" class="text-left text-subtitle-2" v-if="user.is_member === true">
                              {{ user.user_id }} <!--(회원)-->
                            </span>
                            <span style="width: 70%; color: rgb(122 121 121) !important;" class="text-left text-subtitle-2" v-else>
                              {{ user.user_id }} <!--(비회원)-->
                            </span>
                            <span style="width: 30%;" class="text-left text-subtitle-2">{{ user.count }}번</span>
                        </v-list-item>
                        <v-divider></v-divider>
                      </v-list>
                      </div>
                      <div v-else>
                        <v-list dense>
                          <v-list-item>
                            <span style="width: 100%;padding-top: 75%;" class="text-center">해당 {{ date_type_text }}에는 데이터가 없습니다.</span>
                          </v-list-item>
                        </v-list>
                      </div>
                    </v-card>
                  </v-col>
                </v-row>
              </template>
          <template v-slot:footer>
            <v-row
                class="mt-4 mr-1"
                align="end"
                justify="end"
            >
              <span class="mr-4 mt-4 grey--text">
                현재 페이지 : {{ user_pv_page }} / 전체 페이지 : {{ userPvNumberOfPages }}
              </span>
              <v-btn
                  fab
                  small
                  dark
                  color="rgb(56 71 179)"
                  class="mr-1"
                  @click="userPvFormerPage"
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn
                  fab
                  small
                  dark
                  color="rgb(56 71 179)"
                  class="ml-1"
                  @click="userPvNextPage"
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </v-row>
          </template>
        </v-data-iterator>
      </v-card>
    </v-row>
    <v-row class="mb-0">
      <v-col lg="12" class="justify-center pb-0 mb-0">
        <v-row class="ma-0">
          <v-col class="row pb-0 mb-0 pt-0 pr-0">
            <div class="font-weight-bold" style="margin-top: 2%;">플랫폼별 게시글 조회수</div>
            <v-spacer></v-spacer>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="mb-0 mt-0">
      <v-card width="100%" class="ma-3 elevation-0">
        <v-data-iterator
                :items="platform_pv_items"
                :items-per-page.sync="platform_pv_per_page"
                :page.sync="platform_pv_page"
                hide-default-footer
        >
          <template v-slot:default="props">
            <v-row>
              <v-col
                  v-for="item in props.items"
                  :key="item.dateType"
                  cols="12"
                  sm="6"
                  md="6"
                  lg="3"
              >
                <v-card class="elevation-2" style="min-height: 400px;">
                  <v-card-title class="subheading font-weight-bold">
                    {{ item.dateType }}
                    <span v-if="dateChoice === 'monthly'">
                      월
                    </span>
                  </v-card-title>
                  <v-divider></v-divider>
                  <v-card-subtitle class="subheading font-weight-bold row justify-center pl-6" style="width:95%;">
                    <span style="width: 65%;" class="text-left">플랫폼</span>
                    <span style="width: 30%;" class="text-center">게시글 조회수</span>
                  </v-card-subtitle>
                  <v-divider></v-divider>
                  <div v-if="item.platforms">
                    <v-list dense
                      v-for="platform in item.platforms"
                      :key="platform.platform"
                    >
                      <v-list-item >
                        <span style="width: 70%;" class="text-left text-subtitle-2">
                          <span>
                            <v-icon class="mr-2"
                                    :color="$root.getPlatformColorByName(platform.platform)"
                            >{{ $root.getPlatformIconByName(platform.platform) }}</v-icon>
                            {{ $root.getPlatformHanNameByName(platform.platform) }}
                          </span>
                        </span>
                        <span style="width: 30%;" class="text-left text-subtitle-2">{{ platform.count }}번</span>
                      </v-list-item>
                      <v-divider></v-divider>
                    </v-list>
                  </div>
                  <div v-else>
                    <v-list dense>
                      <v-list-item>
                        <span style="width: 100%;padding-top: 25%;" class="text-center">해당 {{ date_type_text }}에는 데이터가 없습니다.</span>
                      </v-list-item>
                    </v-list>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </template>
          <template v-slot:footer>
            <v-row
                class="mt-4 mr-1"
                align="end"
                justify="end"
            >
              <span
                  class="mr-4 mt-4 grey--text"
              >
                현재 페이지 : {{ platform_pv_page }} / 전체 페이지 : {{ platformPvNumberOfPages }}
              </span>
              <v-btn
                  fab
                  small
                  dark
                  color="rgb(56 71 179)"
                  class="mr-1"
                  @click="platformPvFormerPage"
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn
                  fab
                  small
                  dark
                  color="rgb(56 71 179)"
                  class="ml-1"
                  @click="platformPvNextPage"
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </v-row>
          </template>
        </v-data-iterator>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import Breadcrumbs from "../../components/Breadcrumbs";
import Spinner from "../../components/Spinner";
import {mapGetters} from "vuex";
import numeral from 'numeral';
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import * as am4charts from "@amcharts/amcharts4/charts";

import dayjs from "dayjs";

am4core.useTheme(am4themes_animated);

export default {
  components: {
    Breadcrumbs,
    Spinner,
  },
  data() {
    return {
      loading: false,
      breadcrumbs: [
        {
          text: '일간 사용자 유입량',
          disabled: false,
          href: '/statistic-users',
        },
      ],
      uv_pv_items: [],
      uv_pv_items_chart: [],
      uv_pv_headers: [
        {text: 'date.', value: 'dateType', width: '70px', align: 'center'},
        {text: 'UV (방문)',  value: 'uv_count', width: '160px', align: 'center'},
        {text: 'PV (방문)',  value: 'pv_count', width: '160px', align: 'center'},
        {text: 'UV (클릭)',  value: 'article_unique_count', width: '160px', align: 'center'},
        {text: 'PV (클릭)',  value: 'pv_avg_count', width: '160px', align: 'center'},
      ],
      uv_pv_page : 1,
      user_pv_length: 0,
      user_pv_per_page: 4,
      user_pv_page: 1,
      user_pv_items: [],
      platform_pv_length: 0,
      platform_pv_per_page: 4,
      platform_pv_page: 1,
      platform_pv_items: [],
      user_items: [],
      media_id: null,
      datepicker: false,
      end_datepicker: false,
      dateRanges : [],
      dateChoice : 'daily',
      date_type_text : '일',
      yesterday : dayjs(dayjs().format("YYYY-MM-DD")).subtract(1, 'day').format('YYYY-MM-DD'),
      today : dayjs(dayjs().format("YYYY-MM-DD")).format('YYYY-MM-DD')
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
    getMediaId() {
      return this.$store.getters.getMediaId;
    },
    userPvNumberOfPages () {
      return Math.ceil(this.user_pv_items.length / this.user_pv_per_page)
    },
    platformPvNumberOfPages () {
      return Math.ceil(this.platform_pv_items.length / this.platform_pv_per_page)
    },
  },
  watch: {
    dateRanges: {
      handler() {
        if (this.dateRanges[0] > this.dateRanges[1]) {
          this.dateRanges.reverse();
          this.dateSearchChange();
        }
      }
    },
    media_id() {
      this.$store.commit("SET_MEDIA_ID", this.media_id);
    },
    dateChoice(){
      if(this.dateChoice === 'daily'){
        this.date_type_text = '일';
        this.dateRanges[0] = dayjs(dayjs().format("YYYY-MM-DD")).subtract(30, 'day').format('YYYY-MM-DD');
        this.dateSearchChange();
      }
      if(this.dateChoice === 'weekly'){
        this.date_type_text = '주';
        this.dateRanges[0] = dayjs(dayjs().format("YYYY-MM-DD")).subtract(15, 'week').format('YYYY-MM-DD');
        this.dateSearchChange();
      }
      if(this.dateChoice === 'monthly'){
        this.date_type_text = '월';
        this.dateRanges[0] = dayjs(dayjs().format("YYYY-MM-DD")).subtract(15, 'month').format('YYYY-MM-DD');
        this.dateSearchChange();
      }
    }
  },
  mounted() {
    this.dateRanges[0] = dayjs(dayjs().format("YYYY-MM-DD")).subtract(30, 'day').format('YYYY-MM-DD');
    this.dateRanges[1] = this.today;

    this.media_id = this.getMediaId;
    const mediaId = this.media_id;
    if (mediaId) {
      this.media_id = parseInt(mediaId);
    } else {
      this.media_id = this.user.user_media[0].id;
    }
    if (localStorage.getItem('token')) {
      this.user_items = this.user.user_media;
    }
    this.changeDatas();

  },
  methods: {
    userPvNextPage () {
      if (this.user_pv_page + 1 <= this.userPvNumberOfPages) this.user_pv_page += 1
    },
    userPvFormerPage () {
      if (this.user_pv_page - 1 >= 1) this.user_pv_page -= 1
    },
    platformPvNextPage () {
      if (this.platform_pv_page + 1 <= this.platformPvNumberOfPages) this.platform_pv_page += 1
    },
    platformPvFormerPage () {
      if (this.platform_pv_page - 1 >= 1) this.platform_pv_page -= 1
    },
    medias(media) {
      this.changePages();
      this.media_id = media;
      if (typeof this.media_id === "undefined") {
        this.media_id = this.user.user_media[0].id;
      } else {
        this.media_id = media;
      }
      this.changeDatas();
    },
    numberComma(value) {
      return numeral(value).format('0,0');
    },
    getUvPvDailyCount(){ // PV
      let header = this.$root.getMediaEngNameById(this.media_id);
      let result = [];
      let result_chart = [];
      this.loading = true;
      this.axios.get('api/v1/stats/uv-pv-stats', {
        params: {
          'media_id': parseInt(this.media_id),
          'start_date': this.dateRanges ? this.dateRanges[0] : '',
          'end_date': this.dateRanges ? this.dateRanges[1] : '',
          'date_choice': this.dateChoice ? this.dateChoice : 'daily',
        },
        headers: {
          'tv': header
        }
      }).then(res => {
          this.loading = false;
          res.data.data.data.map((item) => {
            let pv_avg_count = Math.round((item.pv_count / item.uv_count) * 100) / 100;
            if(isNaN(pv_avg_count) || pv_avg_count === Infinity) pv_avg_count = 0;
            let dateType = item.dateType;
            if(this.dateChoice === 'daily') {
              dateType = item.dateType+"("+this.getDay(item.dateType)+")";
              dateType = dateType.substr(5);
            }
            if(this.dateChoice === 'weekly') dateType = dateType.substr(4);
            result.push({
              dateType: dateType,
              pv_count: this.numberComma(item.pv_count)+"번",
              uv_count: this.numberComma(item.uv_count)+"명",
              //pv_avg_count: pv_avg_count +"번",
              pv_avg_count: this.numberComma(item.article_count) +"번",
              article_unique_count: this.numberComma(item.article_unique_count) + "번"
            })
            result_chart.push({
              dateType: dateType,
              pv_count: this.numberComma(item.pv_count)+"번",
              uv_count: this.numberComma(item.uv_count)+"명",
              //pv_avg_count: pv_avg_count +"번",
              pv_avg_count: this.numberComma(item.article_count) +"번",
              article_unique_count: this.numberComma(item.article_unique_count) + "번"

            })
          })
          this.uv_pv_items = result;
          this.uv_pv_items_chart = result_chart.reverse();

          this.drawUvPvDailyChart();
          this.loading = false;
        })
        .catch(err => {
          console.error(err);
        });
    },
    drawUvPvDailyChart() { //UV & PV 차트
      let chart = am4core.create(this.$refs.chartUserVisitDailyDiv, am4charts.XYChart);
      chart.colors.step = 2

      chart.data = this.uv_pv_items_chart;
      chart.padding(20, 20, 20, 20)

      let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis())
      categoryAxis.dataFields.category = 'dateType'
      categoryAxis.renderer.minGridDistance = 120
      categoryAxis.fontSize = '14px'

      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis())
      valueAxis.fontSize = '14px'
      valueAxis.renderer.opposite = true
      valueAxis.extraMax = 0.2
      valueAxis.min = 0

      let series = chart.series.push(new am4charts.LineSeries())
      series.dataFields.valueY = 'uv_count'
      series.dataFields.categoryX = 'dateType'
      series.name = 'UV (방문)'
      series.tooltipText = '{name}: [bold]{valueY}명[/]'
      series.tensionX = 0.77
      series.yAxis = valueAxis

      let series2 = chart.series.push(new am4charts.LineSeries())
      series2.dataFields.valueY = 'pv_count'
      series2.dataFields.categoryX = 'dateType'
      series2.name = 'PV (방문)'
      series2.tooltipText = '{name}: [bold]{valueY}번[/]'
      series2.tensionX = 0.77
      series2.yAxis = valueAxis

      let series3 = chart.series.push(new am4charts.LineSeries())
      series3.dataFields.valueY = 'article_unique_count'
      series3.dataFields.categoryX = 'dateType'
      series3.name = 'UV (클릭)'
      series3.tooltipText = '{name}: [bold]{valueY}번[/]'
      series3.tensionX = 0.77
      series3.yAxis = valueAxis

      let series4 = chart.series.push(new am4charts.LineSeries())
      series4.dataFields.valueY = 'pv_avg_count'
      series4.dataFields.categoryX = 'dateType'
      series4.name = 'PV (클릭)'
      series4.tooltipText = '{name}: [bold]{valueY}번[/]'
      series4.tensionX = 0.77
      series4.yAxis = valueAxis

      chart.cursor = new am4charts.XYCursor()
      chart.legend = new am4charts.Legend()
    },
    getUserPageViewDailyCount(){ // 사용자 PV
      let header = this.$root.getMediaEngNameById(this.media_id);
      let result = [];
      this.loading = true;
      this.axios.get('api/v1/users/page-view-stats', {
        params: {
          'media_id': parseInt(this.media_id),
          'start_date': this.dateRanges ? this.dateRanges[0] : '',
          'end_date': this.dateRanges ? this.dateRanges[1] : '',
          'date_choice': this.dateChoice ? this.dateChoice : 'daily',
        },
        headers: {
          'tv': header
        }
      }).then(res => {
          this.loading = false;
          res.data.data.data.map((item) => {
            let dateType = item.dateType;
            if(this.dateChoice === 'daily') {
              dateType = item.dateType+"("+this.getDay(item.dateType)+")";
              dateType = dateType.substr(5);
            }
            if(this.dateChoice === 'weekly') dateType = dateType.substr(4);

            result.push({
              dateType: dateType,
              users: item.users,
            })
          })
          this.user_pv_items = result;
          this.user_pv_length = res.data.data.total;

          this.loading = false;
        })
        .catch(err => {
          console.error(err);
          location.reload();
        });
    },
    getPlatformPageViewDailyCount(){ // 플랫폼당 PV
      let header = this.$root.getMediaEngNameById(this.media_id);
      let result = [];
      this.loading = true;
      this.axios.get('api/v1/platforms/page-view-stats', {
        params: {
          'media_id': parseInt(this.media_id),
          'start_date': this.dateRanges ? this.dateRanges[0] : '',
          'end_date': this.dateRanges ? this.dateRanges[1] : '',
          'date_choice': this.dateChoice ? this.dateChoice : 'daily',
        },
        headers: {
          'tv': header
        }
      }).then(res => {
          this.loading = false;
          res.data.data.data.map((item) => {
            let dateType = item.dateType;
            if(this.dateChoice === 'daily') {
              dateType = item.dateType+"("+this.getDay(item.dateType)+")";
              dateType = dateType.substr(5);
            }
            if(this.dateChoice === 'weekly') dateType = dateType.substr(4);

            result.push({
              dateType: dateType,
              platforms: item.platforms,
            })
          })

          this.platform_pv_items = result;
          this.platform_pv_length = res.data.data.total;
          this.loading = false;
        })
        .catch(err => {
          console.error(err);
        });
    },

    dateSearchChange() {
      this.changePages();
      if (this.dateRanges[0] && this.dateRanges[1]) {
        this.changeDatas();
      } else {
        if(this.dateRanges[0]){
          this.changeDatas();
          this.dateRanges[1] = this.today;
        }else{
          this.$Swal.fire({
            icon: 'error',
            text: '시작 날짜를 선택해주세요.',
          }).then((result) => {
            if (result.isConfirmed) {
              location.reload();
            }
          }).catch(err => {
            console.error(err);
          });
        }
      }
      this.datepicker = false;
      this.end_datepicker = false;
    },
    changeDatas(){
      this.getUvPvDailyCount();
      this.getUserPageViewDailyCount();
      this.getPlatformPageViewDailyCount();
    },
    changePages(){
      this.user_pv_page = 1;
      this.uv_pv_page = 1;
      this.platform_pv_page = 1;
    },
    getDay(date){
      const week = ['일', '월', '화', '수', '목', '금', '토'];
      return week[new Date(date).getDay()];
    }
  }
}
</script>

<style scoped>

</style>
